<template>
  <v-card :style="borderStyle"
          :to="{name: 'SaleProduct', params: {
            productId: product.id,
            product: product.name
          }}"
          class="text-center pt-1 pb-2"
          link shaped>
    <v-img
        :lazy-src="product.imageUrl"
        :src="product.imageUrl"
        contain>
      <template v-slot:placeholder>
        <v-row
            align="center"
            class="fill-height ma-0"
            justify="center"
        >
          <v-progress-circular
              color="grey lighten-5"
              indeterminate
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <span>{{ product.name }}</span>
  </v-card>
</template>

<script>
export default {
  name: 'ProductButton',

  props: {
    'product': Object,
  },

  computed: {
    borderStyle: function () {
      return 'border: 1px solid ' + this.product.color;
    },
  },
};
</script>
