<template>
  <v-container fluid>
    <h1>Dashboard</h1>
    <p>Welkom op het dashboard, klik hieronder op een categorie om statistieken te bekijken.</p>
    <v-row>
      <v-col>
        <v-card :to="{ name: 'SaleGeneral' }" link>
          <v-card-title>Verkoop</v-card-title>
          <v-card-subtitle>Totaal (alle producten)</v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-for="product in products" v-bind:key="product.id" class="col-md-3">
        <ProductButton :product="product"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductButton from '@/components/ProductButton.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DashboardPage',
  components: { ProductButton },

  computed: {
    ...mapGetters({
      products: 'Product/products',
    }),
  },

  created() {
    this.fetch();
  },

  methods: {
    ...mapActions({
      fetch: 'Product/fetchAllProductsAsync',
    }),
  },
};
</script>

<style scoped>

</style>
